<template>
  <div class="columns has-background-white">
    <div class="column">
      <ul 
        class="steps is-thin is-horizontal has-content-above"
      >
        <StepSegment 
          v-for="step in steps"
          :key="step.id"
          :step="step"
          :pageStep="content.step"
        />
      </ul>
      <h1 class="title is-size-5">
       {{content.title}}
      </h1>
      <p 
        class="subtitle is-size-6"
        v-html="content.subtitle"
      >
      </p>
      <slot />
    </div>
    
    <div 
      v-if="content.step == 3 && deal"
      class="column" style="padding:40px;"
    >
      <DealCard 
        :deal="deal" 
        :isLink="false"
        :previewInactive="true"
      />
    </div>
    <div 
      v-else
      class="column has-background-info has-text-white" style="padding:40px;">
      <h1 class="title has-text-white">Local loot</h1>
      <p class="subtitle has-text-white">{{content.sideComponentText}}</p>
    </div>

  </div>
</template>

<script>
import StepSegment from "@/components/StepSegment.vue";
import DealCard from "@/components/DealCard.vue";

export default {
   props: {
    content: {
      type: Object,
      required: true
    },
    deal: {
      Object,
      required: false
    }
  },
  components: {
    StepSegment,
    DealCard
  },
  data() {
    return {
      steps: [
        {
          id: 1,
          name: "Business",
          path: "/o/organization-new",
          hasContent: true
        },
        {
          id: 2,
          name: "Deal",
          path: "/o/organization-deal-category",
          hasContent: true
        },
        {
          id: 3,
          name: "Publish",
          path: "/o/organization-fund",
          hasContent: true
        },
        {
          id: 4,
          name: "Purchase",
          path: "/o/organization-deal-pay",
          hasContent: false
        }
      ]
    }
  },
  mounted() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push("/")
    } 
  },
  methods: {
    addLocation(location) {
      this.$parent.addLocation(location)
    },
    updateBusiness(business) {
      this.$parent.updateBusiness(business)
    },
    onSubmit(value) {
      this.$parent.onSubmit(value)
    },
    uploadImg(image_url) {
      this.$parent.uploadImg(image_url)
    }
  }
}
</script>

<style>
.steps-segment:after {
  background-color:rgb(44,104,210);
}
.steps:not(.is-hollow) .steps-marker:not(.is-hollow) {
  background-color:rgb(44,104,210);
  color: #fff;
}

.steps.is-hollow .is-active .steps-marker, .steps .is-active .steps-marker.is-hollow {
  border-color: rgb(44,104,210);
}
</style>
