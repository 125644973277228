<template>
  <li 
  :class="stepSegmentClass(step.id)"
  >
    <a 
      :to="step.path" 
      :class="stepMarkerClass(step.id)"
    >
    </a>
    <div 
    v-if="step.hasContent"
    class="steps-content is-divider-content">
      <p 
        @click="go(step.path)"
        class="is-size-7"
      >
        {{step.name}}
      </p>
    </div>
  </li>
</template>

<script>
export default {
  props: ["step", "pageStep"],
  computed: {
    link: {
      set() {
        return this.step.path
      },
      get() {
        return this.step.path
      }
    }
  },
  methods: {
    stepSegmentClass(step) {
      if (step == this.pageStep) {
        // console.log("make is active")
        // console.log(step)
        // console.log(this.pageStep)
        return "steps-segment is-active" 
      } else {
        return "steps-segment"
      }
    },
    stepMarkerClass(step) {
      if (step == this.pageStep) {
        // console.log("make is hollow")
        // console.log(step)
        // console.log(this.pageStep)
        return "steps-marker is-hollow" 
      } else {
        return "steps-marker"
      }
    },
    go(path) {
      this.$router.push(path)
    }
  }
};
</script>