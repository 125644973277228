<template>
  <OnboardPage 
    :content="content"
  >  
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <ul>
      <li
        v-for="l in locations"
        :key="l.formattedAddress"
        style="padding:5px 0;"
      >
        <span class="tag is-medium">
          {{ l.formattedAddress }}
          <button 
            @click="deleteLocation(l.formattedAddress)"
            class="delete is-small"></button>
        </span> 
      </li>
    </ul>
    <a
      v-if="!addressFormShow"
      @click="addressFormShow = !addressFormShow"
    >
      Add Location
    </a>
    <BaseAddressSearch
      v-if="addressFormShow"
      v-model="newAddress"
      :business="currentBusiness.name"
    />
    <div class="field" style="margin-top:10px;">
      <p class="control">
        <button
          :disabled="locations.length < 1"
          class="button is-link"
          @click="next()"
        >
          {{content.showNextLabel}}
        </button>
      </p>
    </div>
    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>

  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import OnboardPage from "@/components/OnboardPage.vue";
import Notification from "@/components/Notification.vue";
import BaseAddressSearch from "@/components/form/BaseAddressSearch.vue";

export default {
  components: {
    OnboardPage,
    Notification,
    BaseAddressSearch,
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      addressFormShow: true,
      locations: [],
      newAddress: '',
      content: {
        page: 'organization-locations',
        step: 2,
        title: 'Add locations',
        subtitle: 'Search & select or type in each business location address',
        nextPage: "organization-contact",
        sideComponentText: 'Help us help customers find you.',
        showNextLabel: "Next",
        showNext: true,
        showBack: true
      }
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    currentBusinessAddress: function() {
      return this.$store.getters.currentBusinessAddress;
    }
  },
  mounted() {
    if (this.currentBusiness && this.currentBusiness.slug) {
      

      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.locations = this.currentBusiness.locations
        if (this.currentBusinessAddress.length) {
          this.newAddress = this.currentBusinessAddress
        }
      }  

    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    next() {
      this.submitting = false
      this.$router.push('/o/' + this.content.nextPage)
    },
    addLocation(location) {
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: {
          new_location: location
        }
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.addressFormShow = false
          this.newAddress = ''
          this.locations = this.currentBusiness.locations
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    },
    deleteLocation(formattedAddress) {
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,  
        organization: {
          delete_location: {
            formatted_address: formattedAddress
          }
        }
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.locations = this.currentBusiness.locations
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    }
  }
}
</script>
